import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { FactorService, Factor } from '../shared';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-hw-token-test',
  templateUrl: './hw-token-test.component.html',
  styleUrls: ['./hw-token-test.component.css']
})
export class HwTokenTestComponent implements OnInit {
  hwFactorError = false;
  hwFactorSuccess = false;
  hwFactorErrorMessage = '';

  constructor(
    private factorService: FactorService,
    private oauthService: OAuthService
  ) { }

  ngOnInit(): void {
  }

  get isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  checkHwFactor(factor_serial: string, entered_otp: string): void {
    let factorObj = null;
    factorObj = this.factorService.checkHwFactor(factor_serial, entered_otp);

    factorObj.subscribe(
      data => {
        this.hwFactorError = false;
        this.hwFactorSuccess = true;
      },
      (error: HttpErrorResponse) => {

        this.hwFactorError = true;
        this.hwFactorSuccess = false;
        if (error.status === 405) {
          this.hwFactorErrorMessage = 'You entered an invalid Serial Number.';
        } else if (error.status === 406) {
          this.hwFactorErrorMessage = 'The MFA Code was incorrect. Please try again.';
        } else if (error.status === 409) {
          this.hwFactorErrorMessage = 'The token is broken and must be replaced.';
        } else if (error.status === 403) {
          this.hwFactorErrorMessage = 'You are not authorized to use this tool.';
        } else if (error.status === 404) {
          this.hwFactorErrorMessage = 'User not found!';
        } else if (error.status === 500) {
          this.hwFactorErrorMessage = 'Internal Server Error';
        } else {
          // our token must have expired.
          this.oauthService.initCodeFlow();
        }
      }
    );

  }

}

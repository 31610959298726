import { environment } from '../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { FactorService, Factor } from './shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  showAdmin = false;
  showHwMFA = false;
  hwFactorError = false;
  hwFactorSuccess = false;
  allowHwMfaEnrollment = true;
  hwFactorErrorMessage = '';
  errorMessage = '';
  factorList = [];
  searchResults = null;
  sub: Subscription;
  closeResult: string;
  settingsLink = environment.userSettingsLink;

  constructor(
    private factorService: FactorService,
    private route: ActivatedRoute,
    private oauthService: OAuthService,
    private router: Router) {
    this.oauthService.configure({
      issuer: environment.issuer,
      redirectUri: environment.redirectUri,
      clientId: environment.clientId,
      scope: environment.scope,
      responseType: 'code'
    });

    this.oauthService.events.subscribe(e => {
      if (e.type === 'token_received') {
        this.getFactors();
      }
      console.log('oauth/oidc event', e);
    });

    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  ngOnInit() {
    this.factorService.configure({
      apiUrl: environment.apiUrl
    });
    if (this.oauthService.hasValidAccessToken()) {
      this.getFactors();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  login() {
    // Clean up any existing session info
    this.oauthService.logOut(true);
    this.oauthService.initCodeFlow();
  }

  get isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  get email() {
    let claims = this.oauthService.getIdentityClaims();

    if (!claims) {
      return null;
    }

    return claims['email'];
  }

  get isAdmin(): boolean {
    let claims = this.oauthService.getIdentityClaims();

    if (!claims) {
      return false;
    }

    let isAdmin = false;
    environment.adminGroups.forEach(groupName => {
      if ("groups" in claims) {
        if (claims['groups'].includes(groupName)) {
          isAdmin = true;
        }
      }
    });

    return isAdmin;
  }

  enrollHwFactor(factor_serial: string, entered_otp: string): void {
    let factorObj = null;
    factorObj = this.factorService.addHwFactor(factor_serial, entered_otp);

    factorObj.subscribe(
      data => {
        this.hwFactorError = false;
        this.hwFactorSuccess = true;
      },
      (error: HttpErrorResponse) => {

        this.hwFactorError = true;
        if (error.status === 405) {
          this.hwFactorErrorMessage = 'You entered an invalid Serial Number.';
        } else if (error.status === 406) {
          this.hwFactorErrorMessage = 'The MFA Code was incorrect. Please try again.';
        } else if (error.status === 403) {
          this.hwFactorErrorMessage = 'You are not authorized to use this tool.';
        } else if (error.status === 404) {
          this.hwFactorErrorMessage = 'User not found!';
        } else if (error.status === 500) {
          this.hwFactorErrorMessage = 'Internal Server Error';
        } else {
          // our token must have expired.
          this.oauthService.initCodeFlow();
        }
      }
    );

  }

  getFactors(user?: string): void {
    let factorObj = null;
    if (user) {
      factorObj = this.factorService.getUserFactors(user);
    } else {
      factorObj = this.factorService.getMyFactors();
    }

    factorObj.subscribe(
      data => {
        let factorList = [];
        for (const entry of data) {
          console.log(entry);
          if (entry.factorType === 'token:hotp') {
            this.allowHwMfaEnrollment = false;
          };
          if (entry.factorType !== 'push') {
            factorList.push(new Factor(entry));            
          }
        }
        if (user) {
          this.searchResults = this.sort_array(factorList, 'name', false);
          console.log(this.searchResults);
        } else {
          this.factorList = this.sort_array(factorList, 'name', false);
        }
      },
      (error: HttpErrorResponse) => {
        console.log(error);

        if (error.status === 403) {
          this.errorMessage = 'You are not authorized to use this tool.';
        } else if (error.status === 404) {
          this.errorMessage = 'Not found!';
        } else if (error.status === 500) {
          this.errorMessage = 'Internal Server Error';
        } else {
          // our token must have expired.
          this.oauthService.initCodeFlow();
        }
      }
    );
  }

  deleteFactor(user: string, factorID: string): void {
    this.factorService.deleteUserFactor(user, factorID);
    for (const entry of this.searchResults){
      if (entry.id === factorID) {
        entry.status = "NONE";
      }
    }
  }

  addFactor(user: string, factor_type: string, factor_value: string): void {
    this.factorService.addUserFactor(user, factor_type, factor_value);
  }

  sort_array(arr: Array<Factor>, prop: any, reverse: boolean = false): Array<Factor> {
    const m = reverse ? -1 : 1;
    return arr.sort((a: any, b: any): number => {
      const x = a[prop];
      const y = b[prop];
      return (x === y) ? 0 : (x < y) ? -1 * m : 1 * m;
    });
  }

  intersect(a, b): any {
    let t;
    if (b.length > a.length) {
      t = b, b = a, a = t;
    }
    return a.filter(function (e) {
      return b.indexOf(e) > -1;
    });
  }
}

import { Injectable } from '@angular/core';
import { Resolve , ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class RedirectService implements Resolve<string> {

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    console.log(route);
    window.location.href = (route.data as any).externalUrl;
  }
}

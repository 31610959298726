import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { RedirectComponent } from './redirect/redirect.component';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { FactorService, AuthGuard, RedirectService } from './shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HwTokenTestComponent } from './hw-token-test/hw-token-test.component';
import { MainComponent } from './main/main.component';

const appRoutes: Routes = [
  { path: 'test-hw-token', component: HwTokenTestComponent},
  {
    path: 'faq',
    component: RedirectComponent,
    resolve: {
      url: RedirectService
    },
    data: {
      externalUrl: environment.redirectLinks['faq']
    }
  },
  {
    path: 'FAQ',
    component: RedirectComponent,
    resolve: {
      url: RedirectService
    },
    data: {
      externalUrl: environment.redirectLinks['faq']
    }
  },
  { path: '', component: MainComponent, pathMatch: 'full' },
  { path: '**', component: MainComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
    HwTokenTestComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    RouterModule.forRoot(appRoutes),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true
      }
    }),
    FormsModule,
    HttpClientModule
  ],
  entryComponents: [],
  providers: [
    AuthGuard,
    FactorService,
    RedirectService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable()
export class FactorService {

  baseURL: string;

  constructor(private http: HttpClient, private oauthService: OAuthService) {}

  configure(configData) {
    this.baseURL = configData['apiUrl'];
  }

  getMyFactors(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + this.oauthService.getAccessToken());

    return this.http.get(this.baseURL + '/my_factors', {headers: headers});
  }

  getUserFactors(user: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + this.oauthService.getAccessToken());
    return this.http.get(this.baseURL + '/factors?user=' + user, {headers: headers});
  }

  deleteUserFactor(user: string, id: string): any {
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + this.oauthService.getAccessToken());
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    console.log(headers);

    let body = JSON.stringify({
        'user': user, 
        'id': id
      });

    this.http.request('DELETE', this.baseURL + '/factors', {headers: headers, body: body}).subscribe((ok) => {
        console.log('Factor deleted: ' + id);
        return ok;
      }
    );
  }

  addHwFactor(factor_serial: string, entered_otp: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + this.oauthService.getAccessToken());
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    let body = JSON.stringify({
          'factor_serial': factor_serial,
          'entered_otp': entered_otp
        });
    return this.http.post(this.baseURL + '/hw_tokens', body, {headers: headers});
  }

  checkHwFactor(factor_serial: string, entered_otp: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + this.oauthService.getAccessToken());
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    let body = JSON.stringify({
          'factor_serial': factor_serial,
          'entered_otp': entered_otp
        });
    return this.http.post(this.baseURL + '/check_hw_token', body, {headers: headers});
  }

  addUserFactor(user: string, factor_type: string, factor_value: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + this.oauthService.getAccessToken());
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    let body = JSON.stringify({
        'user': user,
        'factor_type': factor_type,
        'factor_value': factor_value
      });

    return this.http.post(this.baseURL + '/factors', body, {headers: headers});
  }
}

export class FactorProfile {
  answer: string;
  credentialId: string;
  phoneNumber: string;
  question: string;
  questionText: string;
  name: string;
  authenticatorName: string;

  constructor(obj?: any) {
    this.answer = obj && obj.answer || null;
    this.name = obj && obj.name || null;
    this.credentialId = obj && obj.credentialId || null;
    this.phoneNumber = obj && obj.phoneNumber || null;
    this.question = obj && obj.question || null;
    this.questionText = obj && obj.questionText || null;
    this.authenticatorName = obj && obj.authenticatorName || null;
  }
}

export class Factor {
  id: string;
  factorType: string;
  status: string;
  provider: string;
  profile: FactorProfile;
  icon: string;
  name: string;
  alert: boolean;

  constructor(obj?: any) {
    this.id = obj && obj.id || null;
    this.factorType = obj && obj.factorType || null;
    this.status = obj && obj.status || null;
    this.provider = obj && obj.provider || null;
    this.profile = obj && obj.profile || null;
    this.alert = false;

    if (this.factorType === 'sms') {
      this.icon = 'fas fa-comment-alt';
      this.name = 'SMS';
      this.alert = true;
    } else if (this.factorType === 'push') {
      this.icon = 'fas fa-mobile-alt';
      this.name = 'Okta Verify (push)';
    } else if (this.factorType === 'email') {
      this.icon = 'fas fa-at';
      this.name = 'Personal Email';
      this.alert = true;
    }else if (this.factorType === 'token:hotp') {
      this.icon = "fas fa-shield-alt";
      this.name = obj.vendorName;
      this.profile = new FactorProfile();
    }else if (this.factorType === 'u2f' || this.factorType === 'webauthn') {
      this.icon = "fas fa-fingerprint";
      if (this.profile.authenticatorName) {
        this.name = "WebAuthn Token: " + this.profile.authenticatorName
      } else {
        this.name = "WebAuthn Hardware or Biometric Token";
      }
        this.profile = new FactorProfile();
    } else if (this.factorType === 'token:software:totp') {
      if (this.provider === 'GOOGLE') {
        this.icon = 'fab fa-google';
        this.name = 'Google Authenticator';
      } else {
        this.icon = 'fas fa-mobile-alt';
        this.name = 'Okta Verify (OTP)';
      }
    } else if (this.factorType === 'call') {
      this.icon = 'fas fa-phone-volume';
      this.name = 'Voice Call';
      this.alert = true;
    } else if (this.factorType === 'signed_nonce') {
      if ('platform' in this.profile) {
        if (this.profile['platform'] === 'IOS' || this.profile['platform'] === 'MACOS') {
          this.icon = 'fab fa-apple';
        } else if (this.profile['platform'] === 'ANDROID') {
          this.icon = 'fab fa-android';
        } else if (this.profile['platform'] === 'WINDOWS') {
          this.icon = 'fab fa-windows';
        }
        this.name = 'Okta Verify: ' + this.profile['platform'] + ' (' + this.profile['name'] + ')';
      }
    }
  }
}
